<template>
  <div class="assessment-results">
    <div class="logo"><router-link to="/"><img src="@/assets/images/storesafe-logo.png" width="100%" /></router-link></div>
    <button @click="continueAssessment" class="btn-continue exlude-from-pdf mobile">Continue This Assessment</button>
    <h1 class="assessment-title">{{ assessmentTitle }}</h1>
    
    <div class="assessment-meta">
      <div class="assessment-date">
        <strong>Started:</strong> {{ formatDate(assessment.createdAt) }}
      </div>
      <strong>Progress:</strong> <span class="completion-percent">{{ getCompletionPercentage }}</span>
      <div class="progress-bar">
        <div class="progress-fill" :style="{ width: `${calculateProgress}%` }"></div>
      </div>
    </div>
    
    <h2 style="text-align: center;width:100%;">Assessment Results</h2>
    <div class="total-score">
      <ResultsRing
        category="Total Score"
        :score="totalScore"
        :size="300"
        :weights="questionWeights"
      />
    </div>
    <p v-if="!isComplete">Note: This assessment is incomplete. Results are based on answered questions only.</p>
    <div class="results-grid">
      <ResultsRing
        v-for="category in categories"
        :key="category"
        :category="category"
        :score="getCategoryScore(category)"
        :weights="questionWeights"
      />
    </div>
    <div class="results-button-right"><button @click="viewDetailedReport" class="detail-button exlude-from-pdf">View Detailed Report</button></div>
    <div class="results-button-left"><button @click="downloadPDF" class="download-button exlude-from-pdf">Download Scores (PDF)</button></div>
  </div>
</template>

<script>
import ResultsRing from './ResultsRing.vue';
import html2pdf from 'html2pdf.js';
import { useRouter } from 'vue-router';

export default {
  components: {
    ResultsRing
  },
  props: {
    answers: {
      type: Object,
      required: true
    },
    questions: {
      type: Array,
      required: true
    },
    currentQuestionIndex: {
      type: Number,
      required: true
    },
    assessmentTitle: {
      type: String,
      required: true
    },
    assessmentId: {  // Add this prop
      type: [String, Number],
      required: true
    },
    assessment: {
      type: Object,
      required: true
    }
  },
  setup() {
    const router = useRouter(); // Initialize router
    return { router };
  },
  data() {
    return {
      categories: ['Food Safety', 'Space Optimization', 'Cost Savings', 'Labor Efficiency', 'Sustainability'],
      userType: null
    };
  },
  created() {
    const hasRepWeights = this.questions.some(q => 
      q.weightFoodSafety !== undefined || 
      q.weightSpaceOptimization !== undefined || 
      q.weightCostSavings !== undefined ||
      q.weightLaborEfficiency !== undefined ||
      q.weightSustainability !== undefined
    );
    this.userType = hasRepWeights ? 'Rep' : 'Customer';
  },
  computed: {
    isComplete() {
      return this.questions.every(q => this.answers[q.id] !== undefined);
    },
    totalScore() {
      const scores = this.categories.map(category => this.getCategoryScore(category));
      return scores.reduce((a, b) => a + b, 0) / this.categories.length;
    },
    questionWeights() {
      return this.questions.reduce((acc, question) => {
        this.categories.forEach(category => {
          const weightKey = `weight${category.replace(/\s+/g, '')}`;
          acc[weightKey] = (acc[weightKey] || 0) + (question[weightKey] || 0);
        });
        return acc;
      }, {});
    },
    calculateProgress() {
      if (!this.questions || !this.answers) return 0;
      const answeredQuestions = Object.keys(this.answers).length;
      return Math.round((answeredQuestions / this.questions.length) * 100);
    },
    getCompletionPercentage() {
      return `${this.calculateProgress}% Complete`;
    }
  },
methods: {
    viewDetailedReport() {
      if (this.assessmentId) {
        this.router.push(`/assessment/${this.assessmentId}/report`);
      } else {
        console.error('Assessment ID is undefined');
      }
    },
    getCategoryScore(category) {
      const fieldMap = {
        'Food Safety': this.userType === 'Rep' ? 'weightFoodSafety' : 'food_safety_points',
        'Space Optimization': this.userType === 'Rep' ? 'weightSpaceOptimization' : 'space_optimization_points',
        'Cost Savings': this.userType === 'Rep' ? 'weightCostSavings' : 'cost_savings_points',
        'Labor Efficiency': this.userType === 'Rep' ? 'weightLaborEfficiency' : 'labor_efficiency_points',
        'Sustainability': this.userType === 'Rep' ? 'weightSustainability' : 'sustainability_points'
      };
    
      const weightKey = fieldMap[category];
      const questionsInCategory = this.questions.filter(q => q[weightKey] > 0);
      
      if (questionsInCategory.length === 0) return 0;
      
      const totalPoints = questionsInCategory.reduce((sum, q) => sum + q[weightKey], 0);
      const earnedPoints = questionsInCategory.reduce((sum, q) => 
        sum + (this.answers[q.id] ? q[weightKey] : 0), 0
      );
      
      const viewDetailedReport = () => {
        router.push(`/assessment/${assessmentId}/report`);
      };
  
      return (earnedPoints / totalPoints) * 100;
    },
    formatDate(dateString) {
      if (!dateString) return 'N/A';
      return new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    },
    downloadPDF() {
      const element = document.querySelector('.assessment-results');
      const opt = {
        margin: 10,
        filename: `${this.assessmentTitle}_scores.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      };
    
      html2pdf().set(opt).from(element).save();
    },
    continueAssessment() {
      this.$emit('continue-assessment');
    }
  }
};
</script>