<template>
 <div class="carousel-container">
   <div 
     class="carousel-track" 
     :style="trackStyle"
     @transitionend="handleTransitionEnd"
   >
     <div 
       v-for="(image, index) in allSlides" 
       :key="index"
       class="carousel-slide"
     >
       <img 
         :src="image"
         :alt="`Slide ${index}`"
         class="carousel-image"
       />
     </div>
   </div>

   <button class="carousel-nav prev" @click="prevSlide" aria-label="Previous slide">
     <span>‹</span>
   </button>
   <button class="carousel-nav next" @click="nextSlide" aria-label="Next slide">
     <span>›</span>
   </button>
 </div>
</template>

<script>
export default {
 name: 'ImageCarousel',
 data() {
   return {
     currentIndex: 1,
     transitionEnabled: true,
     timer: null,
     baseImages: [
       require('@/assets/images/slider1.jpg'),
       require('@/assets/images/slider2.jpg'),
       require('@/assets/images/slider3.jpg'),
       require('@/assets/images/slider4.jpg'),
       require('@/assets/images/slider5.jpg')
     ]
   }
 },
 computed: {
   allSlides() {
     return [
       this.baseImages[this.baseImages.length - 1],
       ...this.baseImages,
       this.baseImages[0]
     ]
   },
   trackStyle() {
     return {
       transform: `translateX(-${this.currentIndex * 100}%)`,
       transition: this.transitionEnabled ? 'transform 0.5s ease-in-out' : 'none'
     }
   }
 },
 methods: {
   nextSlide() {
     this.currentIndex++
     this.resetTimer()
   },
   prevSlide() {
     this.currentIndex--
     this.resetTimer()
   },
   handleTransitionEnd() {
     if (this.currentIndex >= this.baseImages.length + 1) {
       this.transitionEnabled = false
       this.currentIndex = 1
     }
     if (this.currentIndex === 0) {
       this.transitionEnabled = false
       this.currentIndex = this.baseImages.length
     }
     if (!this.transitionEnabled) {
       requestAnimationFrame(() => {
         requestAnimationFrame(() => {
           this.transitionEnabled = true
         })
       })
     }
   },
   resetTimer() {
     clearInterval(this.timer)
     this.startTimer()
   },
   startTimer() {
     this.timer = setInterval(this.nextSlide, 3000)
   }
 },
 mounted() {
   this.startTimer()
 },
 beforeUnmount() {
   clearInterval(this.timer)
 }
}
</script>

<style scoped>
.carousel-container {
 width: 100%;
 max-width: 800px;
 margin: 0 auto;
 position: relative;
 overflow: hidden;
 border-radius: 10px;
}

.carousel-track {
 display: flex;
 width: 100%;
}

.carousel-slide {
 flex: 0 0 100%;
 width: 100%;
}

.carousel-image {
 width: 100%;
 height: 100%;
 object-fit: cover;
 aspect-ratio: 1 / 1;
}

.carousel-nav {
 position: absolute;
 top: 50%;
 transform: translateY(-50%);
 background: rgba(0, 0, 0, 0.5);
 color: white;
 border: none;
 padding: 1rem 0.5rem;
 cursor: pointer;
 font-size: 2rem;
 display: flex;
 align-items: center;
 justify-content: center;
 transition: background-color 0.3s;
 z-index: 2;
 width: 2vw;
}

.carousel-nav:hover {
 background: rgba(0, 0, 0, 0.8);
}

.carousel-nav.prev {
 left: 0;
 border-radius: 3px;
}

.carousel-nav.next {
 right: 0;
 border-radius: 3px 0 0 3px;
}

.carousel-nav span {
 display: block;
 line-height: 0;
}

@media (max-width: 768px) {
 .carousel-nav {
   padding: 0.5rem 0.25rem;
   font-size: 1.5rem;
 }
}
</style>