<template>
  <div class="home">
    <div class="logo-left"><router-link to="/"><img src="@/assets/images/storesafe-logo.png" width="100%" /></router-link></div>
    <h1>StoreSafe Solutions</h1>
    <h2>Launched From One Driving Force: Helping Operators Keep Food Safe</h2>
  </div>

  <div class="home-grey">
    <h4 class="home-start">See if you're meeting your Food Safety goals. Take our free online assessment today.</h4>
    <button v-if="!isLoggedIn" @click="navigateTo('/register')" class="btn-home-start">Take the StoreSafe Solutions Assessment</button>
    <button v-else @click="navigateTo('/start-assessment')" class="btn-home-start">Take the StoreSafe Solutions Assessment</button>
    <br class="cleaner" />

    <div class="home-container">
      <div class="home-mission">
        <div class="home-mission-header">&nbsp;</div>
        <h4>StoreSafe Solutions empowers operators to achieve their food safety, efficiency, sustainability, and cost savings goals through Cambro product education, assessments, collaboration, and training.</h4>
      </div>
      <div class="home-what">
        <div class="home-what-header">&nbsp;</div>
        <h4>A dedicated Cambro resource, StoreSafe Solutions supports HACCP best practices at critical points of foodservice, from Receiving, Storage, Preparation, Service, and Warewashing.</h4>
      </div>
    </div>
  </div>

  <div class="home-white">
    <h1>StoreSafe Solutions</h1>
    <h3>Explore the kitchen spaces below to see how operators use NSF-Listed Cambro products for optimized, HACCP-compliant performance.</h3>
    <!-- <h1>Resources</h1> -->
    <div class="home-categories">
      <router-link :to="isIframe ? '/storesafe/receiving' : '/receiving'" class="home-cat">
        <div class="cat-receiving"></div>
      </router-link>
      <router-link :to="isIframe ? '/storesafe/storage' : '/storage'" class="home-cat">
        <div class="cat-storage"></div>
      </router-link>
      <router-link :to="isIframe ? '/storesafe/preparation' : '/preparation'" class="home-cat">
        <div class="cat-prep"></div>
      </router-link>
      <router-link :to="isIframe ? '/storesafe/service' : '/service'" class="home-cat">
        <div class="cat-service"></div>
      </router-link>
      <router-link :to="isIframe ? '/storesafe/warewashing' : '/warewashing'" class="home-cat">
        <div class="cat-warewashing"></div>
      </router-link>
    </div>
    <div class="carousel-right">
      <!-- <h1>StoreSafe Resources</h1> -->
      <!-- <a href="/StoreSafeBrochure.pdf" target="_blank"><button class="btn-home-brochure">StoreSafe Solutions Brochure</button></a> -->
      <button v-if="!isLoggedIn" @click="navigateTo('/register')" class="btn-home-brochure">
        Take the StoreSafe Solutions Assessment
      </button>
      <button v-else @click="navigateTo('/start-assessment')" class="btn-home-brochure">
        Take the StoreSafe Solutions Assessment
      </button>
    </div>
    <div class="carousel-left">
      <div class="carousel-section">
        <ImageCarousel />
      </div>
    </div>
    <div class="cleaner"></div>
  </div>
</template>

<script>
import ImageCarousel from '@/components/ImageCarousel.vue'

export default {
  name: 'Home',
  components: {
    ImageCarousel
  },
  data() {
    return {
      user: null
    };
  },
  computed: {
    isLoggedIn() {
      return !!this.user;
    },
    isIframe() {
      return window.self !== window.top;
    }
  },
  methods: {
    navigateTo(route) {
      this.$router.push(route);
    },
    handleLoginSuccess(user) {
      this.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    },
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      this.user = null;
      this.$router.push('/login');
    }
  },
  created() {
    const user = localStorage.getItem('user');
    if (user) {
      this.user = JSON.parse(user);
    }
  }
};
</script>
