<template>
  <div id="app" :class="currentRouteName">
    <div class="cambro-logo" v-if="showLogo"><a href="https://www.cambro.com" target="_blank"><img src="@/assets/images/cambro-logo.png" width="100%" /></a></div>
    <div class="menu-container" :class="{ 'menu-open': isMenuOpen }">
      <button class="hamburger mobile" @click="toggleMenu">
        <span :class="{ 'active': isMenuOpen }"></span>
      </button>

      <!-- <nav>
        <router-link v-if="!isLoggedIn" to="/register" @click.native="closeMenu">Create an Account</router-link>
        <router-link v-if="!isLoggedIn" to="/login" @click.native="closeMenu">Log In</router-link>
        <span v-if="isLoggedIn">Welcome, {{ user?.name }}!</span>
        <router-link v-if="isLoggedIn" to="/start-assessment" @click.native="closeMenu">New Assessment</router-link>
        <router-link v-if="isLoggedIn" to="/dashboard" @click.native="closeMenu">Dashboard</router-link>
        <a v-if="isLoggedIn" href="#" @click.prevent="logout">Logout</a>
        <router-link v-if="isAdmin" to="/admin" @click.native="closeMenu">Admin Dashboard</router-link>
      </nav> -->
      
      <nav>
        <span v-if="isLoggedIn" class="nav-welcome">Welcome, {{ user?.name }}!</span>
        <div v-if="showSolutionsMenu" class="solutions-menu">
          <div class="solutions-toggle" @click="toggleSolutions">
            Solutions
            <i class="fas fa-caret-down" :class="{ 'rotate': isSolutionsOpen }"></i>
          </div>
          <div v-if="isSolutionsOpen" class="solutions-submenu">
            <router-link to="'${$route.meta.basePath}/receiving'" @click="closeMenu">Receiving</router-link>
            <router-link to="'${$route.meta.basePath}/storage'" @click="closeMenu">Storage</router-link>
            <router-link to="'${$route.meta.basePath}/preparation'" @click="closeMenu">Preparation</router-link>
            <router-link to="'${$route.meta.basePath}/servic'" @click="closeMenu">Service</router-link>
            <router-link to="'${$route.meta.basePath}/warewashing'" @click="closeMenu">Warewashing</router-link>
          </div>
        </div>
        <!-- <router-link v-if="!isLoggedIn" to="/register" @click.native="closeMenu">Create an Account</router-link> -->
        <router-link v-if="!isLoggedIn" to="/login" @click.native="closeMenu">My Account</router-link>
        <router-link v-if="isLoggedIn" to="/start-assessment" @click.native="closeMenu">New Assessment</router-link>
        <router-link v-if="isLoggedIn" to="/dashboard" @click.native="closeMenu">My Dashboard</router-link>
        <router-link v-if="isAdmin" to="/admin" @click.native="closeMenu">Admin Dashboard</router-link>
        <router-link to="'${$route.meta.basePath}/receiving'" @click="closeMenu" class="mobile">Receiving</router-link>
        <router-link to="'${$route.meta.basePath}/storage'" @click="closeMenu" class="mobile">Storage</router-link>
        <router-link to="'${$route.meta.basePath}/preparation'" @click="closeMenu" class="mobile">Preparation</router-link>
        <router-link to="'${$route.meta.basePath}/service'" @click="closeMenu" class="mobile">Service</router-link>
        <router-link to="'${$route.meta.basePath}/warewashing'" @click="closeMenu" class="mobile">Warewashing</router-link>
        <a v-if="isLoggedIn" href="#" @click.prevent="logout">Logout</a>
      </nav>
    </div>
    <router-view @login-success="handleLoginSuccess" @register-success="handleLoginSuccess"/>
  </div>
</template>

<script>
import { auth } from '@/api';

export default {
  name: 'App',
  data() {
    return {
      isMenuOpen: false,
      user: null
    };
  },
  data() {
    return {
      isMenuOpen: false,
      user: null,
      isSolutionsOpen: false
    };
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  computed: {
    showLogo() {
      const staticPages = ['Home', 'Receiving', 'Storage', 'Preparation', 'Service', 'Warewashing', 'AdminDashboard'];
      return !staticPages.includes(this.$route.name);
    },
    isLoggedIn() {
      return !!this.user;
    },
    isAdmin() {
      return this.user?.isAdmin || false;
    },
    currentRouteName() {
      return this.$route.name || '';
    },
    showSolutionsMenu() {
      const staticRoutes = ['Home', 'Receiving', 'Storage', 'Preparation', 'Service', 'Warewashing'];
      return staticRoutes.includes(this.currentRouteName);
    }
  },
  methods: {
    closeMenu() {
      this.isMenuOpen = false;
      this.isSolutionsOpen = false;
    },
    toggleSolutions() {
      this.isSolutionsOpen = !this.isSolutionsOpen;
    },
    handleClickOutside(event) {
      const menu = document.querySelector('.solutions-menu');
      if (menu && !menu.contains(event.target) && this.isSolutionsOpen) {
        this.isSolutionsOpen = false;
      }
    },
    async checkAuth() {
      try {
        const { isAuthenticated, user } = await auth.checkAuth();
        if (isAuthenticated) {
          this.user = user;
        } else {
          this.user = null;
        }
      } catch (error) {
        console.error('Auth check failed:', error);
        this.user = null;
      }
    },
    handleLoginSuccess(user) {
      console.log('Login/Register success:', user);
      this.user = user;
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    async logout() {
      try {
        await auth.logout();
        this.user = null;
        this.isMenuOpen = false;
        this.$router.push('/login');
      } catch (error) {
        console.error('Logout failed:', error);
      }
    }
  },
  created() {
    this.checkAuth();
  }
};
</script>