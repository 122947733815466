<template>
  <div class="combined-results container">
	<button @click="continueAssessment" class="btn-continue exlude-from-pdf desktop">Continue This Assessment</button>

	<div v-if="loading">Loading...</div>
	<div v-else-if="error">{{ error }}</div>
	<div v-else class="assessment-results">
	  <div class="logo">
		<router-link to="/">
		  <img src="@/assets/images/storesafe-logo.png" width="100%" alt="StoreSafe logo" />
		</router-link>
	  </div>

	  <button @click="continueAssessment" class="btn-continue exlude-from-pdf mobile">Continue This Assessment</button>

	  <h1 class="assessment-title">{{ assessment.title }}</h1>
	  
	  <div class="assessment-meta">
		<div class="assessment-date">
		  <strong>Started:</strong> {{ formatDate(assessment.createdAt) }}
		</div>
		<strong>Progress:</strong> <span class="completion-percent">{{ getCompletionPercentage }}</span>
		<div class="progress-bar">
		  <div class="progress-fill" :style="{ width: `${calculateProgress}%` }"></div>
		</div>
	  </div>
	  
	  <h2 style="text-align: center;width:100%;">Assessment Results</h2>
	  <div class="total-score">
		<ResultsRing
		  category="Total Score"
		  :score="totalScore"
		  :size="300"
		  :weights="questionWeights"
		/>
	  </div>
	  
	  <p v-if="!isComplete">Note: This assessment is incomplete. Results are based on answered questions only.</p>
	  
	  <div class="results-grid">
		<ResultsRing
		  v-for="category in categories"
		  :key="category"
		  :category="category"
		  :score="getCategoryScore(category)"
		  :weights="questionWeights"
		/>
	  </div>

	  <div class="download-section exclude-from-pdf">
		<button @click="downloadPDF" class="download-button exlude-from-pdf">
		  Download Complete Report (PDF)
		</button>
	  </div>

	  <!-- Detailed Report Section -->
	  <div class="detailed-report-section">
		<h2>Detailed Assessment Report</h2>
		
		<div v-for="category in sortedCategories" 
			 :key="category" 
			 class="category-section">
		  <h2 class="category-title">{{ category }}</h2>
		  
		  <div v-for="question in getQuestionsForCategory(category)" 
			   :key="question.id" 
			   class="question-container">
			<div class="question-header">
			  <h3 class="question-text">{{ question.question }}</h3>
			  <div :class="['answer-badge', getAnswerClass(question.id)]">
				{{ getAnswerText(question.id) }}
			  </div>
			</div>

			<div v-if="notes[question.id]" class="question-notes">
			  <div class="notes-label">Notes:</div>
			  <p class="notes-text">{{ notes[question.id] }}</p>
			</div>

			<div v-if="questionImages[question.id]?.length" class="images-grid">
			  <div v-for="image in questionImages[question.id]" 
				   :key="image.id" 
				   class="image-container">
				<img :src="image.path" :alt="'Supporting image for question ' + question.id">
			  </div>
			</div>
		  </div>
		</div>
	  </div>
	</div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import ResultsRing from '@/components/ResultsRing.vue';
import html2pdf from 'html2pdf.js';
import { auth } from '@/api';

const route = useRoute();
const router = useRouter();

const assessment = ref(null);
const questions = ref([]);
const questionImages = ref({});
const loading = ref(true);
const error = ref(null);
const notes = ref({});

const categories = ['Food Safety', 'Space Optimization', 'Cost Savings', 'Labor Efficiency', 'Sustainability'];
const userType = ref('Customer');

const isComplete = computed(() => {
  return questions.value.every(q => assessment.value?.answers[q.id] !== undefined);
});

const totalScore = computed(() => {
  const scores = categories.map(category => getCategoryScore(category));
  return scores.reduce((a, b) => a + b, 0) / categories.length;
});

const questionWeights = computed(() => {
  return questions.value.reduce((acc, question) => {
	categories.forEach(category => {
	  const weightKey = `weight${category.replace(/\s+/g, '')}`;
	  acc[weightKey] = (acc[weightKey] || 0) + (question[weightKey] || 0);
	});
	return acc;
  }, {});
});

const calculateProgress = computed(() => {
  if (!questions.value || !assessment.value?.answers) return 0;
  const answeredQuestions = Object.keys(assessment.value.answers).length;
  return Math.round((answeredQuestions / questions.value.length) * 100);
});

const getCompletionPercentage = computed(() => {
  return `${calculateProgress.value}% Complete`;
});

const sortedCategories = computed(() => {
  const orderMap = {
	'receiving': 1,
	'storage': 2,
	'preparation': 3,
	'service': 4,
	'sanitation': 5
  };
  
  const categories = new Set(questions.value.map(q => q.category || 'Uncategorized'));
  return Array.from(categories).sort((a, b) => {
	return (orderMap[a.toLowerCase()] || 99) - (orderMap[b.toLowerCase()] || 99);
  });
});

const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  return new Date(dateString).toLocaleDateString('en-US', {
	year: 'numeric',
	month: 'short',
	day: 'numeric'
  });
};

const getCategoryScore = (category) => {
  const fieldMap = {
	'Food Safety': userType.value === 'Rep' ? 'weightFoodSafety' : 'food_safety_points',
	'Space Optimization': userType.value === 'Rep' ? 'weightSpaceOptimization' : 'space_optimization_points',
	'Cost Savings': userType.value === 'Rep' ? 'weightCostSavings' : 'cost_savings_points',
	'Labor Efficiency': userType.value === 'Rep' ? 'weightLaborEfficiency' : 'labor_efficiency_points',
	'Sustainability': userType.value === 'Rep' ? 'weightSustainability' : 'sustainability_points'
  };

  const weightKey = fieldMap[category];
  const questionsInCategory = questions.value.filter(q => q[weightKey] > 0);
  
  if (questionsInCategory.length === 0) return 0;
  
  const totalPoints = questionsInCategory.reduce((sum, q) => sum + q[weightKey], 0);
  const earnedPoints = questionsInCategory.reduce((sum, q) => 
	sum + (assessment.value.answers[q.id] ? q[weightKey] : 0), 0
  );

  return (earnedPoints / totalPoints) * 100;
};

const getQuestionsForCategory = (category) => {
  return questions.value.filter(q => q.category === category);
};

const getAnswerClass = (questionId) => {
  if (assessment.value?.answers[questionId] === undefined) return 'not-answered';
  return assessment.value.answers[questionId] ? 'yes' : 'no';
};

const getAnswerText = (questionId) => {
  if (assessment.value?.answers[questionId] === undefined) return 'Not answered';
  return assessment.value.answers[questionId] ? 'Yes' : 'No';
};

const fetchQuestionImages = async (assessmentId, questionId) => {
  try {
	const response = await axios.get(
	  `/api/assessment/${assessmentId}/question/${questionId}/images`,
	  {
		withCredentials: true,
		baseURL: process.env.VUE_APP_API_URL || 'https://mystoresafe.com'
	  }
	);
	return response.data;
  } catch (error) {
	console.error('Error fetching images:', error);
	return [];
  }
};

const downloadPDF = () => {
  const element = document.querySelector('.combined-results');
  const opt = {
	margin: 10,
	filename: `${assessment.value.title}_complete_report.pdf`,
	image: { type: 'jpeg', quality: 0.98 },
	html2canvas: { scale: 2 },
	jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
  };

  html2pdf().set(opt).from(element).save();
};

const continueAssessment = () => {
  router.push({
	name: 'Assessment',
	params: { id: assessment.value.id }
  });
};

const fetchData = async () => {
  try {
	loading.value = true;
	const [assessmentResponse, questionsResponse] = await Promise.all([
	  axios.get(`/api/assessment/${route.params.id}`, {
		withCredentials: true,
		baseURL: 'https://mystoresafe.com'
	  }),
	  axios.get('/api/questions', {
		withCredentials: true,
		baseURL: 'https://mystoresafe.com'
	  })
	]);

	assessment.value = assessmentResponse.data;
	questions.value = questionsResponse.data;
	notes.value = assessment.value.notes || {};

	// Fetch images for each question
	const imagePromises = questions.value.map(async (question) => {
	  const images = await fetchQuestionImages(route.params.id, question.id);
	  questionImages.value[question.id] = images;
	});

	await Promise.all(imagePromises);

	// Determine user type based on question structure
	const hasRepWeights = questions.value.some(q => 
	  q.weightFoodSafety !== undefined || 
	  q.weightSpaceOptimization !== undefined || 
	  q.weightCostSavings !== undefined ||
	  q.weightLaborEfficiency !== undefined ||
	  q.weightSustainability !== undefined
	);
	userType.value = hasRepWeights ? 'Rep' : 'Customer';

  } catch (error) {
	console.error('Error fetching data:', error);
	error.value = error.message;
  } finally {
	loading.value = false;
  }
};

onMounted(async () => {
  try {
	const authResponse = await auth.checkAuth();
	if (!authResponse.isAuthenticated) {
	  router.push('/login');
	  return;
	}
	await fetchData();
  } catch (error) {
	console.error('Auth check failed:', error);
	router.push('/login');
  }
});
</script>