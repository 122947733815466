<template>
  <div class="static preparation">
	<div class="logo-left">
	  <router-link to="/">
		<img src="@/assets/images/storesafe-logo.png" width="100%" />
	  </router-link>
	</div>
	<h1>Preparation</h1>
  </div>

  <div class="static-grey">
	Control food safety risks in the kitchen by preparing and holding food at the correct temperature, minimizing handling, and keeping an organized workspace. Eliminate cross-contamination from dirt, rust, or other foods by utilizing durable, NSF-listed preparation tools.
  </div>
  <div class="static-white">

	<div class="static-split">
	  <div class="split-image">
		<img src="@/assets/images/prep_shelving.jpg" alt="Ingredient bins" class="rounded">
	  </div>
	  <div class="split-content">
		<h2>Camshelving Elevation Series Wall Shelving Prep Station</h2>
		<p>Food safety culture starts at the prep table. Promote food safety and prep station efficiency with organized, rust-free, and easy to clean Wall Shelving made from Camshelving and CamGuard technologies.</p>
		<a href="https://www.cambro.com/Products/shelving/camshelving-elevation-series-wall-shelving/standard-station-kits/EWS36PREP110" target="_blank" class="learn-more">
		<button @mouseover="isHovered = true" 
				@mouseleave="isHovered = false"
				:class="{ 'hovered': isHovered }">
		  Learn More
		</button></a>
	  </div>
   </div>
   
   
   <div class="static-split red-line">
	   <div class="split-image">
		 <img src="@/assets/images/prep_trolley.jpg" alt="Ingredient bins">
	   </div>
	   <div class="split-content">
		 <h2>Mobile Trolleys: Ultimate Sheet Pan Rack & GN Food Pan Trolley</h2>
		 <p>Transport product quickly, quietly, and all in one run, increasing efficiency and minimizing product time spent in the temperature danger zone. These mobile trolleys are made from strong composite material, offering ZERO percent chance of contamination from metal shavings, and yet stronger than steel.</p>
		 <a href="https://www.cambro.com/Products/food-and-salad-bars/prep-and-transport-racks/ultimate-sheet-pan-rack/UPR1826F20580" target="_blank" class="learn-more">
		 <button @mouseover="isHovered = true" 
				 @mouseleave="isHovered = false"
				 :class="{ 'hovered': isHovered }">
		   Learn More about Ultimate Sheet Pan Rack
		 </button></a>
		 <a href="https://www.cambro.com/Products/food-and-salad-bars/prep-and-transport-racks/camshelving-gn-food-pan-trolleys/UGNPR11F18480" target="_blank" class="learn-more">
		  <button @mouseover="isHovered = true" 
				  @mouseleave="isHovered = false"
				  :class="{ 'hovered': isHovered }">
			Learn More about GN Food Pan Trolley
		  </button></a>
	   </div>
	</div>
	
	<div class="static-testimonial">
		<div class="quote-top"><img src="@/assets/images/quote-top.jpg" /></div>
		<div class="testimonial-quote">Now that we have the Cambro trolleys, it’s been a gamechanger for us; our USDA inspector loves it, our team loves it. Not only is it more heavy duty and doesn’t rust or corrode, it’s lighter. We were literally able to scale up production three times our capacity.&quot;</div>
		<div class="testimonial-pic"><img src="@/assets/images/prep_testimonial-bill-stitt.jpg" /></div>
		<div class="testimonial-name"><a href="https://blog.cambro.com/2023/02/28/bill-es-small-batch-bacon/ " target="_blank">Bill Stitt, Owner of Bill-E’s Small Batch Bacon</a></div>
	</div>
	 
   
	<div class="static-split red-line">
		<div class="split-image">
		<img src="@/assets/images/prep_camwear.jpg" alt="Ingredient bins">
		</div>
		<div class="split-content">
		<h2>Camwear and Translucent Food Pans</h2>
		<p>Hold ingredients safely on the prep table, all while maximizing product quality. Added lids and flip lids minimize handling. Colander Food Pans help reduce food waste when storing prepped produce, defrosting meat, storing seafood on ice and more.</p>
		<a href="https://www.cambro.com/Products/food-pans-and-lids/camwear-colander-food-pans/" target="_blank" class="learn-more">
		<button @mouseover="isHovered = true" 
				@mouseleave="isHovered = false"
				:class="{ 'hovered': isHovered }">
			Learn More
		</button></a>
		</div>
	</div>
	 
	<div class="static-testimonial">
		<div class="quote-top"><img src="@/assets/images/quote-top.jpg" /></div>
		<div class="testimonial-quote">We lay the mozzarella balls out and we get a double drain on them. Before, we weren’t getting that with the old metal pans. The drainage is better, which makes a better product for us.&quot;</div>
		<div class="testimonial-pic"><img src="@/assets/images/prep_testimonial-1.png" /></div>
		<div class="testimonial-name"><a href="https://blog.cambro.com/2023/01/25/righteous-slice/" target="_blank">Bill Crawford, Founder of Righteous Slice</a></div>
	</div>
	 
	 
	<div class="static-split red-line">
		<div class="split-image">
		  <img src="@/assets/images/prep_h-pan.jpg" alt="CamSquares">
		</div>
		<div class="split-content">
		  <h2>H-Pan High Heat Food Pans</h2>
		  <p>From flash freezing to reheating and draining, this all-purpose high heat food pan helps protect food-safe temperatures, reduce cross contamination risk with compatible lids and flip lids, and minimize product waste.</p>
		  <a href="https://www.cambro.com/Products/food-pans-and-lids/h-pan-high-heat-colander-food-pans/" target="_blank" class="learn-more">
		  <button @mouseover="isHovered = true" 
				  @mouseleave="isHovered = false"
				  :class="{ 'hovered': isHovered }">
			Learn More
		  </button></a>
		</div>
	 </div>
	 
	 <div class="static-testimonial">
		<div class="quote-top"><img src="@/assets/images/quote-top.jpg" /></div>
		<div class="testimonial-quote">We will make about 40 gallons of sauce and then we portion those into the Cambro [High Heat H-] Pans and then they go right on the [GN Food Pan Trolley]. We let them cool down for a period of time before we take them to the walk-in.&quot;</div>
		<div class="testimonial-pic"><img src="@/assets/images/prep_testimonial-2.png" /></div>
		<div class="testimonial-name"><a href="https://blog.cambro.com/2024/03/25/the-old-spaghetti-factory-protects-famous-sauce-utilizing-cambro-products/" target="_blank">Brian Sullivan, Director of Kitchen Operations at the Old Spaghetti Factory</a></div>
	</div>
	
	 
	 <div class="static-split red-line">
		 <div class="split-image">
		   <img src="@/assets/images/prep_high-heat.jpg" alt="CamSquares">
		 </div>
		 <div class="split-content">
		   <h2>Measuring Cups</h2>
		   <p>Measuring cups not only improve accuracy and organization in the prep kitchen but minimize handling. Choose from Camwear, Allergen-Free, or High Heat materials to protect the contents of your recipe.</p>
		   <a href="https://www.cambro.com/Products/food-storage/measuring-cups-and-scoops/high-heat-measuring-cups--cover/" target="_blank" class="learn-more">
		   <button @mouseover="isHovered = true" 
				   @mouseleave="isHovered = false"
				   :class="{ 'hovered': isHovered }">
			 Learn More
		   </button></a>
		 </div>
	  </div>
		
	 <div class="static-split red-line">
	   <div class="split-image">
		 <img src="@/assets/images/prep_measuring.jpg" alt="Camwear Boxes with sliding lids">
	   </div>
	   <div class="split-content">
		 <h2>Measuring Cups Allergen Free</h2>
		 <p>Isolate allergen-free prep from The Big Nine with easily identifiable containers that assist your staff in carrying out their food allergy procedures.</p>
		 <a href="https://www.cambro.com/Products/food-storage/measuring-cups-and-scoops/measuring-cups-allergen-free/" target="_blank" class="learn-more">
		 <button @mouseover="isHovered = true" 
				 @mouseleave="isHovered = false"
				 :class="{ 'hovered': isHovered }">
		   Learn More
		 </button></a>
	   </div>
	  </div>
	  
	  <div class="static-split red-line">
		<div class="split-image">
		  <img src="@/assets/images/storage_ingredient-bins.jpg" alt="Ingredient bins">
		</div>
		<div class="split-content">
		  <h2>Ingredient Bins/SlidingLid&trade; Ice Caddies</h2>
		  <p>Store bulk dry ingredients and ice in identifiable and hygienic bins, designed with sliding lids to minimize opening and handling. Dispense bulk dry ingredients and ice with designated, unbreakable Camwear Scoops.</p>
		  <a href="https://www.cambro.com/Products/food-storage/mobile-ingredient-bins/ingredient-bins/" target="_blank" class="learn-more">
		  <button @mouseover="isHovered = true" 
				  @mouseleave="isHovered = false"
				  :class="{ 'hovered': isHovered }">
			Learn More Ingredient Bins
		  </button></a>
		  <a href="https://www.cambro.com/Products/beverage-bars/ice-caddies/slidinglid-ice-caddies/" target="_blank" class="learn-more">
		  <button @mouseover="isHovered = true" 
				  @mouseleave="isHovered = false"
				  :class="{ 'hovered': isHovered }">
			Learn More Sliding Lid Ice Caddies
		  </button></a>
		</div>
	 </div>
	  
	 <div class="static-split red-line">
	   <div class="split-image">
		 <img src="@/assets/images/prep_scoops.jpg" alt="CamSquares">
	   </div>
	   <div class="split-content">
		 <h2>Camwear Scoops</h2>
		 <p>Dispense bulk dry ingredients and ice with designated, unbreakable Camwear Scoops, eliminating bare-hand handling, or residue from plastic gloves, glass cups, and other makeshift, unsafe dispensers.</p>
		 <a href="https://www.cambro.com/Products/food-storage/measuring-cups-and-scoops/camwear-scoops/" target="_blank" class="learn-more">
		 <button @mouseover="isHovered = true" 
				 @mouseleave="isHovered = false"
				 :class="{ 'hovered': isHovered }">
		   Learn More
		 </button></a>
	   </div>
	  </div>
	  
	  <div class="sub-white">
		  <h1>Other Resources</h1>
		  <div class="home-categories">
			  <a href="/receiving" class="home-cat"><div class="cat-receiving"></div></a>
			  <a href="/storage" class="home-cat"><div class="cat-storage"></div></a>
			  <a href="#" class="home-cat dimmed"><div class="cat-prep"></div></a>
			  <a href="/service" class="home-cat"><div class="cat-service"></div></a>
			  <a href="/warewashing" class="home-cat"><div class="cat-warewashing"></div></a>
		  </div>
	  </div>
	  
  </div>


</template>

