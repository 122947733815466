<template>
  <div class="dashboard">
    <div class="logo"><router-link to="/"><img src="@/assets/images/storesafe-logo.png" width="100%" /></router-link></div>
    <div class="dash-message">
      <h3>Our StoreSafe Assessment is a detailed intake of how well your team is staying up to FDA Food Code.</h3>
      Fill out the assessment to see how your operation scores on Food Safety and see how your Food Safety score impacts your Cost Savings, Labor Efficiency, Space Optimization, and Sustainability initiatives. Once you complete your assessment, you’ll receive an automated report on Cambro product solutions based on your answers.
    </div>
    <div class="dash-header">
      <button @click="startNewAssessment" class="btn-start">New Assessment</button>
      <h1>Your Assessments</h1>
      <!-- <div v-if="user.isRep">
        <h1>Your Representative Assessments</h1>
        <AssessmentList :assessments="assessments" />
      </div>
      <div v-else>
        <h1>Your Assessments</h1>
        <AssessmentList :assessments="assessments" />
      </div> -->
    </div>
    <div class="dash-assess">
      <div v-for="assessment in assessments" :key="assessment.id" class="dash-box">
		  <div v-if="editingId === assessment.id">
		    <input 
              v-model="editTitle" 
              @keyup.enter="updateAssessmentTitle(assessment)"
              @keyup.esc="cancelEdit"
              class="form-control"
              placeholder="Enter new title"
              autofocus
            >
		    <button @click="updateAssessmentTitle(assessment)" class="save-btn">Save</button>
            <button @click="cancelEdit" class="cancel-btn">Cancel</button>
		  </div>
		  <h2>{{ assessment.title }}</h2>
		  <div class="assessment-meta">
            <div class="assessment-date">
              <strong>Started:</strong> {{ formatDate(assessment.createdAt) }}
            </div>
            <strong>Progress:</strong> <span class="completion-percent">{{ getCompletionPercentage(assessment) }}</span>
            <div class="progress-bar">
              <div class="progress-fill" :style="{ width: `${calculateProgress(assessment)}%` }"></div>
            </div>
          </div>
		  <button @click="continueAssessment(assessment.id)" class="dash-continue">Continue</button>
		  <button @click="viewResults(assessment)" class="dash-results">View Results</button>
		  <button @click="startEdit(assessment)" class="dash-edit">Edit Title</button>
		  <button @click="deleteAssessment(assessment)" class="dash-delete">Delete</button>
		  <div class="cleaner"></div>
		</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { setRouter, auth } from '@/api';

export default {
  name: 'Dashboard',
  setup() {
    const store = useStore();
    const router = useRouter();
    setRouter(router);

    const assessments = ref([]);
    const editingId = ref(null);
    const editTitle = ref('');

    const fetchAssessments = async () => {
      try {
        const authResponse = await auth.checkAuth();
        if (!authResponse.isAuthenticated) {
          router.push('/login');
          return;
        }
    
        console.log('Auth status:', {
          isAuthenticated: authResponse.isAuthenticated,
          user: authResponse.user,
          isRep: authResponse.user?.isRep
        });
    
        const response = await axios.get(`/api/assessment/user/${authResponse.user.id}`, {
          withCredentials: true,
          baseURL: process.env.VUE_APP_API_URL || 'https://mystoresafe.com',
        });
    
        if (Array.isArray(response.data)) {
          assessments.value = response.data.map((assessment) => ({
            ...assessment,
            totalQuestions: authResponse.user?.isRep ? 50 : 30,
          }));
          console.log('Fetched assessments:', assessments.value.length);
        } else {
          console.error('Expected array of assessments, got:', typeof response.data);
          assessments.value = [];
        }
      } catch (error) {
        console.error('Error fetching assessments:', error);
        assessments.value = [];
      }
    };

    const formatDate = (dateString) => {
      if (!dateString) return 'N/A';
      return new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    };

    const calculateProgress = (assessment) => {
      if (!assessment?.answers) return 0;
    
      const validAnswers = Object.values(assessment.answers)
        .filter(answer => typeof answer === 'boolean').length;
      
      // Get the authenticated user from store
      const user = store.state.auth?.user;
      const totalQuestions = user?.isRep ? 50 : 30;
      
      console.log('Progress calculation:', {
        assessmentId: assessment.id,
        validAnswers,
        totalQuestions,
        isRep: user?.isRep,
        progressPercentage: Math.min(Math.round((validAnswers / totalQuestions) * 100), 100)
      });
    
      return Math.min(Math.round((validAnswers / totalQuestions) * 100), 100);
    };
    
    const getCompletionPercentage = (assessment) => {
      return `${calculateProgress(assessment)}% Complete`;
    };

    const deleteAssessment = async (assessment) => {
      if (confirm('Are you sure you want to delete this assessment?')) {
        try {
          // Log 1: Starting deletion attempt
          console.log('Starting deletion for assessment:', {
            id: assessment.id,
            title: assessment.title,
            fullURL: `${process.env.VUE_APP_API_URL || 'https://mystoresafe.com'}/api/assessment/${assessment.id}`
          });
    
          // Make the delete request
          const response = await axios({
            method: 'delete',
            url: `/api/assessment/${assessment.id}`,
            withCredentials: true,
            baseURL: process.env.VUE_APP_API_URL || 'https://mystoresafe.com',
            // Add timeout to ensure we get a response
            timeout: 5000,
            // Add headers for debugging
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          }).catch(error => {
            // Log 2: Detailed error if request fails
            console.error('Delete request failed:', {
              status: error.response?.status,
              statusText: error.response?.statusText,
              data: error.response?.data,
              message: error.message,
              config: {
                url: error.config?.url,
                method: error.config?.method,
                baseURL: error.config?.baseURL
              }
            });
            throw error;
          });
    
          // Log 3: Successful response
          console.log('Delete response received:', {
            status: response.status,
            data: response.data
          });
    
          // Update UI
          assessments.value = assessments.value.filter(a => a.id !== assessment.id);
          
          // Log 4: UI Update confirmation
          console.log('UI updated - Assessment removed. Remaining count:', assessments.value.length);
    
        } catch (error) {
          // Log 5: Final error catching
          console.error('Delete operation failed:', {
            error: error.message,
            type: error.name,
            stack: error.stack
          });
          alert(`Failed to delete assessment: ${error.message}`);
        }
      }
    };
    
    const startEdit = (assessment) => {
      editingId.value = assessment.id;
      editTitle.value = assessment.title || ''; // Initialize with current title
      console.log('Starting edit for:', {
        id: assessment.id,
        title: assessment.title,
        editTitle: editTitle.value
      });
    };
    
    const cancelEdit = () => {
      editingId.value = null;
      editTitle.value = '';
    };
    
    const updateAssessmentTitle = async (assessment) => {
      try {    
        const response = await axios.put(`/api/assessment/${assessment.id}`, {
          title: editTitle.value,
          answers: assessment.answers,
          currentQuestionIndex: assessment.currentQuestionIndex,
          userId: assessment.userId,
          completed: assessment.completed
        }, {
          withCredentials: true,
          baseURL: 'https://mystoresafe.com'
        });
        
        // Update the local assessment title
        const updatedAssessment = assessments.value.find(a => a.id === assessment.id);
        if (updatedAssessment) {
          updatedAssessment.title = editTitle.value;
        }
        
        editingId.value = null;
        editTitle.value = '';
    
        console.log('Title updated successfully:', response.data);
      } catch (error) {
        console.error('Error updating assessment title:', error);
      }
    };


    const continueAssessment = (id) => {
      console.log('Continuing assessment:', id);
      router.push({
        name: 'Assessment',  // Changed from RepAssessment/CustomerAssessment to just Assessment
        params: { id: String(id) }
      }).catch(err => {
        console.error('Navigation error:', err);
        // Fallback navigation if router push fails
        window.location.href = `/assessment/${id}`;
      });
    };

    const viewResults = (assessment) => {
      console.log('Navigating to results:', assessment.id);
      router.push({
        name: 'Results',
        params: { id: String(assessment.id) }
      }).catch(err => console.error('Navigation error:', err));
    };

    const startNewAssessment = () => {
      console.log('Starting new assessment');
      router.push({ name: 'StartAssessment' })
        .catch(err => console.error('Navigation error:', err));
    };

    onMounted(async () => {
      console.log('Component mounted');
      try {
        const authStatus = await auth.checkAuth();
        console.log('Auth status in component:', authStatus);
        
        if (!authStatus.isAuthenticated) {
          console.log('Not authenticated in component');
          router.push('/login');
          return;
        }
        
        await fetchAssessments();
      } catch (error) {
        console.error('Error in mounted:', error);
        router.push('/login');
      }
    });

    return {
      assessments,
      editingId,
      editTitle,
      fetchAssessments,
      continueAssessment,
      viewResults,
      startNewAssessment,
      startEdit,
      cancelEdit,
      updateAssessmentTitle,
      deleteAssessment,
      formatDate,
      calculateProgress,
      getCompletionPercentage
    };
  }
};
</script>
