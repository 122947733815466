<template>
  <div class="w-full">
	<div class="flex flex-wrap gap-4">
	  <div v-for="(image, index) in safeImages" :key="index" class="relative w-24 h-24 group">
		<img
		  :src="image.path"
		  :alt="`Upload ${index + 1}`"
		  class="w-full h-full object-cover rounded-lg cursor-pointer"
		  @click="enlargedImage = image"
		/>
		<button
		  @click="$emit('delete', image.id)"
		  class="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity"
		>
		  ×
		</button>
	  </div>
	  
	  <label v-if="safeImages.length < maxImages" class="w-24 h-24 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center cursor-pointer hover:border-gray-400">
		<input
		  type="file"
		  class="hidden"
		  accept="image/*"
		  @change="$emit('upload', $event)"
		/>
		<span class="text-gray-500 text-2xl">+</span>
	  </label>
	</div>

	<div v-if="enlargedImage" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
	  <div class="relative max-w-4xl max-h-4xl bg-white rounded-lg p-2">
		<img
		  :src="enlargedImage.path"
		  alt="Enlarged view"
		  class="max-w-full max-h-[80vh] object-contain"
		/>
		<button
		  @click="enlargedImage = null"
		  class="absolute -top-2 -right-2 bg-white rounded-full p-1 shadow-lg hover:bg-gray-100"
		>
		  ×
		</button>
	  </div>
	</div>
  </div>
</template>

<script>
export default {
  name: 'ImageViewer',
  props: {
	images: {
	  type: Array,
	  default: () => []
	},
	maxImages: {
	  type: Number,
	  default: 3
	}
  },
  data() {
	return {
	  enlargedImage: null
	}
  },
  computed: {
	safeImages() {
	  return Array.isArray(this.images) ? this.images : [];
	}
  },
  emits: ['upload', 'delete']
}
</script>