<template>
  <div class="result-ring">
    <svg :width="size" :height="size" viewBox="0 0 36 36" class="circular-chart">
      <path class="circle-bg"
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path :class="['circle', colorClass]"
        :stroke-dasharray="`${percentage}, 100`"
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <text x="18" y="20.35" class="percentage">{{ isNaN(percentage) ? 'N/A' : percentage + '%' }}</text>
    </svg>
    <h3>{{ category }}</h3>
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  props: ['category', 'score', 'weights', 'size'],
  data() {
    return {
      size: 100,
      colorRanges: [
        { max: 70, color: 'red' },
        { max: 75, color: 'light-orange' },
        { max: 80, color: 'orange' },
        { max: 85, color: 'light-green' },
        { max: 99, color: 'medium-green' },
        { max: 100, color: 'dark-green' }
      ],
      categoryMessages: {
        "Food Safety": [
          { max: 70, text: "There's Food Safety work to be done! Find all the Cambro product solutions you need here." },
          { max: 75, text: "There's still room to perfect your Food Safety craft. Find the Cambro product solutions you need here." },
          { max: 80, text: "You're doing good. See what it takes to be GREAT using these Cambro product solutions." },
          { max: 85, text: "You're almost there! Fill in your Food Safety gaps with these Cambro product solutions." },
          { max: 99, text: "Your Food Safety practices are pretty darn good, huh? But not perfect! See what it takes to score 100% here." },
          { max: 100, text: "Simply perfect!" }
        ],
        "Cost Savings": [
          { max: 70, text: "There's work to be done! Find all the Cambro product solutions you need here." },
          { max: 75, text: "There's still room to improve your Cost Savings. Find the Cambro product solutions you need here." },
          { max: 80, text: "You're doing good. See what it takes to be GREAT using these Cambro product solutions." },
          { max: 85, text: "You're almost there! Fill in your Cost Savings gaps with these Cambro product solutions." },
          { max: 99, text: "Your Cost Savings practices are pretty darn good, huh? But not perfect! See what it takes to score 100% here." },
          { max: 100, text: "Simply perfect!" }
        ],
        "Labor Efficiency": [
          { max: 70, text: "There's work to be done! Find all the Cambro product solutions you need here." },
          { max: 75, text: "There's still room to improve your Labor Efficiency. Find the Cambro product solutions you need here." },
          { max: 80, text: "You're doing good. See what it takes to be GREAT using these Cambro product solutions." },
          { max: 85, text: "You're almost there! Fill in your Labor Efficiency gaps with these Cambro product solutions." },
          { max: 99, text: "Your Labor Efficiency practices are pretty darn good, huh? But not perfect! See what it takes to score 100% here." },
          { max: 100, text: "Simply perfect!" }
        ],
        "Space Optimization": [
          { max: 70, text: "There's work to be done! Find all the Cambro product solutions you need here." },
          { max: 75, text: "There's still room to improve your Space Optimization. Find the Cambro product solutions you need here." },
          { max: 80, text: "You're doing good. See what it takes to be GREAT using these Cambro product solutions." },
          { max: 85, text: "You're almost there! Fill in your Space Optimization gaps with these Cambro product solutions." },
          { max: 99, text: "Your Space Optimization practices are pretty darn good, huh? But not perfect! See what it takes to score 100% here." },
          { max: 100, text: "Simply perfect!" }
        ],
        "Sustainability": [
          { max: 70, text: "There's work to be done! Find all the Cambro product solutions you need here." },
          { max: 75, text: "There's still room to improve your Sustainability efforts. Find the Cambro product solutions you need here." },
          { max: 80, text: "You're doing good. See what it takes to be GREAT using these Cambro product solutions." },
          { max: 85, text: "You're almost there! Fill in your Sustainability gaps with these Cambro product solutions." },
          { max: 99, text: "Your Sustainability practices are pretty darn good, huh? But not perfect! See what it takes to score 100% here." },
          { max: 100, text: "Simply perfect!" }
        ],
        "Overall Score": [
          { max: 70, text: "" },
          { max: 75, text: "" },
          { max: 80, text: "" },
          { max: 85, text: "" },
          { max: 99, text: "" },
          { max: 100, text: "" }
        ]
      }
    };
  },
  computed: {
    weightedScore() {
      return this.score;
    },
    percentage() {
      return isNaN(this.score) ? 0 : Math.round(this.score);
    },
    colorClass() {
      const range = this.colorRanges.find(range => this.score <= range.max);
      return range ? range.color : 'dark-green';
    },
    message() {
      const categorySpecificMessages = this.categoryMessages[this.category] || this.categoryMessages["Total Score"];
      if (!categorySpecificMessages) {
        console.warn(`No messages found for category: ${this.category}`);
        return "Category not recognized";
      }
      const messageObj = categorySpecificMessages.find(msg => this.weightedScore <= msg.max);
      return messageObj ? messageObj.text : "Simply perfect!";
    }
  },  
};
</script>