<template>
  <div class="assessment container">
  <div class="logo">
    <img src="@/assets/images/storesafe-logo.png" alt="StoreSafe logo" />
  </div>

  <h1 class="assessment-title">{{ assessmentTitle }}</h1>
  
  <div class="category-navigation">
    <div v-for="category in categories" :key="category" class="category-section">
    <button 
      :class="{ active: currentCategory === category }"
      @click="jumpToCategory(category)"
    >
      {{ category }}
    </button>
    <div class="question-dots">
      <span 
      v-for="(question, index) in questionsByCategory[category]" 
      :key="index"
      class="dot"
      :class="[getDotClass(question.id), 
          { 'current-question': currentCategory === category && index === currentQuestionIndex }]"
      ></span>
    </div>
    </div>
  </div>

  <div v-if="loading">Loading questions...</div>
  <div v-else-if="error">Error: {{ error }}</div>
  
  <div v-if="currentQuestion" class="question-container">
        <button @click="navigateQuestion(-1)" class="nav-button left">
          <img src="@/assets/images/left-arrow.svg" alt="Previous Question" class="nav-arrow"/>
        </button>
        
        <div class="question-content">
          <h2>
            {{ getQuestionNumber(currentCategory, currentQuestionIndex) }}. 
            <span v-html="currentQuestion.question"></span>
          </h2>
          
          <div class="yes-no">
            <button 
              @click="answerQuestion(currentQuestion.id, true)" 
              :class="['yes', { active: answers[currentQuestion.id] === true }]"
            >
              Yes
            </button>
            <button 
              @click="answerQuestion(currentQuestion.id, false)" 
              :class="['no', { active: answers[currentQuestion.id] === false }]"
            >
              No
            </button>
          </div>

          <div class="image-upload-section"
               @dragenter.prevent="handleDrag"
               @dragleave.prevent="handleDrag"
               @dragover.prevent="handleDrag"
               @drop.prevent="handleDrop"
               :class="{ 'drag-active': isDragging }">
            
            <div v-if="questionImages.length < 3" class="upload-area">
              <label class="upload-label">
                <input 
                  type="file"
                  accept="image/*"
                  class="hidden"
                  @change="handleFileSelect"
                  ref="fileInput"
                />
                <span>{{ isDragging ? 'Drop image here' : 'Drag and drop an image or click to upload (optional)' }}</span>
              </label>
              <p class="image-count">{{ questionImages.length }} of 3 images (max file size 1MB)</p>
            </div>
          
            <div class="image-preview-grid">
              <div v-for="image in questionImages" 
                   :key="image.id" 
                   class="image-preview">
                <img :src="image.path" :alt="`Uploaded image`" />
                <button @click="deleteImage(image.id)" class="delete-button">×</button>
              </div>
            </div>
          </div>
          
          <!-- Notes Section -->
          <div class="notes-section">
            <label>Notes (optional)</label>
            <textarea
              v-model="notes[currentQuestion?.id]"
              @change="saveNotes"
              maxlength="200"
              rows="3"
              placeholder="Add notes about this answer..."
              class="notes-input"
            ></textarea>
            <p class="char-count">{{ 200 - (currentNotes?.length || 0) }} characters remaining</p>
          </div>
          
        </div>
        
        <button @click="navigateQuestion(1)" class="nav-button right">
          <img src="@/assets/images/right-arrow.svg" alt="Next Question" class="nav-arrow"/>
        </button>
      </div>
  
      <button @click="saveAndReturn" class="save-progress">
        Save & Return to Dashboard
      </button>
    </div>
  </template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import AssessmentResults from '@/components/AssessmentResults.vue';
import { auth } from '@/api';
import ImageViewer from '@/components/ImageViewer.vue';
import _ from 'lodash';

export default {
  name: 'Assessment',
  components: { 
    AssessmentResults,
    ImageViewer,
  },
  data() {
  return {
    images: []
  };
  },
  setup() {
  const store = useStore();
  const router = useRouter();
  const route = useRoute();
  const isAdminView = computed(() => route.params.adminView === 'true');
  const assessmentType = computed(() => route.query.type);

  // State
  const assessmentId = ref(route.params.id);
  const assessmentTitle = ref('');
  const questions = ref([]);
  const categories = ref(['receiving', 'storage', 'preparation', 'service', 'sanitation']);
  const currentCategory = ref('');
  const currentQuestionIndex = ref(0);
  const questionsByCategory = ref({});
  const lastAnsweredQuestionIndex = ref({});
  const answers = ref({});
  const notes = ref({});
  const loading = ref(true);
  const error = ref(null);
  const isRep = computed(() => store.state.auth?.user?.isRep || false);
  const files = ref([]);
  const isDragging = ref(false);
  const questionImages = ref([]);
  
  // Image upload handling
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    isDragging.value = e.type === 'dragenter' || e.type === 'dragover';
  };
  
  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    isDragging.value = false;
    
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if (questionImages.value.length >= 3) {
        alert('Maximum 3 images allowed');
        return;
      }
      await uploadImage(e.dataTransfer.files[0]);
    }
  };
  
  const handleFileSelect = async (e) => {
    const file = e.target.files?.[0];
    if (!file) return;
    
    if (questionImages.value.length >= 3) {
      alert('Maximum 3 images allowed');
      return;
    }
    
    try {
      await uploadImage(file);
      e.target.value = ''; // Reset input
    } catch (error) {
      alert('Failed to upload image: ' + error.message);
    }
  };
  
  // Notes handling
  const currentNotes = computed({
    get: () => notes.value[currentQuestion.value?.id] || '',
    set: (newValue) => {
      if (!currentQuestion.value) return;
      notes.value = {
        ...notes.value,
        [currentQuestion.value.id]: newValue
      };
    }
  });
  
const saveNotes = async () => {
    try {
      if (!currentQuestion.value?.id) return;
  
      // Simply save the assessment - notes are included in the payload
      await saveAssessment();
      
      console.log('Notes saved with assessment:', {
        questionId: currentQuestion.value.id,
        note: notes.value[currentQuestion.value.id]
      });
    } catch (error) {
      console.error('Error saving notes:', error);
    }
  };
  
  const fetchNotes = async (questionId) => {
    try {
      const response = await axios.get(
        `/api/assessment/${assessmentId.value}/question/${questionId}/notes`,
        {
          withCredentials: true,
          baseURL: process.env.VUE_APP_API_URL || 'https://mystoresafe.com'
        }
      );
  
      if (response.data && response.data.length > 0) {
        notes.value[questionId] = response.data[0].note;
      }
  
      console.log('Fetched notes:', {
        questionId,
        note: notes.value[questionId]
      });
    } catch (error) {
      console.error('Error fetching notes:', error.response?.data || error.message);
    }
  };
  
  watch(
    () => currentQuestion.value?.id,
    async (newId) => {
      if (newId) {
        await fetchNotes(newId);
        await fetchQuestionImages(newId);
      }
    }, 
    { immediate: true }
  );
  
  const fetchQuestionImages = async (questionId) => {
    try {
      const response = await axios.get(
        `/api/assessment/${assessmentId.value}/question/${questionId}/images`,
        {
          withCredentials: true,
          baseURL: process.env.VUE_APP_API_URL || 'https://mystoresafe.com'
        }
      );
  
      // Filter images for the current question
      questionImages.value = response.data;
      console.log('Fetched Images:', questionImages.value);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };
  
  const uploadImage = async (file) => {
    if (file.size > 10 * 1024 * 1024) { // 10MB in bytes
      throw new Error('File size exceeds 1MB limit');
    }
    try {
      const formData = new FormData();
      formData.append('image', file);
      formData.append('questionId', currentQuestion.value.id);
      formData.append('assessment_type', isRep.value ? 'rep' : 'customer');
  
      const response = await axios.post(
        `/api/assessment/${assessmentId.value}/upload-image`, 
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          withCredentials: true,
          baseURL: process.env.VUE_APP_API_URL || 'https://mystoresafe.com'
        }
      );
  
      if (response.status === 201) {
        await fetchQuestionImages(currentQuestion.value.id);
      }
  
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Failed to upload image. Please ensure file is under 10MB.');
    }
  };
  
  const deleteImage = async (imageId) => {
    try {
      await axios.delete(`/api/assessment/image/${imageId}`);
      await fetchQuestionImages(currentQuestion.value.id);
    } catch (error) {
      console.error('Delete failed:', error);
    }
  };
  
  
  
   
  const organizeQuestionsByCategory = () => {
    if (!Array.isArray(questions.value)) {
    console.error('Questions is not an array:', questions.value);
    return;
    }
  
    console.log('Organizing questions by category:', questions.value.length);
  
    questionsByCategory.value = categories.value.reduce((acc, category) => {
    acc[category] = questions.value
      .filter(q => q.category.toLowerCase() === category.toLowerCase())
      .map(q => ({
      ...q, 
      category,
      images: [] // Initialize empty images array for each question
      }));
    
    console.log(`Category ${category}:`, acc[category].length, 'questions');
    return acc;
    }, {});
  };
  
  const initializeLastAnsweredIndices = () => {
    categories.value.forEach(category => {
    if (!lastAnsweredQuestionIndex.value[category]) {
      const categoryQuestions = questionsByCategory.value[category] || [];
      const lastAnsweredIndex = categoryQuestions.findIndex(q => answers.value[q.id] === undefined);
      lastAnsweredQuestionIndex.value[category] = lastAnsweredIndex === -1 ? categoryQuestions.length : lastAnsweredIndex;
    }
    });
  };
  
  const setInitialCategory = () => {
    currentCategory.value = categories.value[0];
    currentQuestionIndex.value = lastAnsweredQuestionIndex.value[currentCategory.value] || 0;
  };
  
  const jumpToCategory = (category) => {
    console.log('Jumping to category:', category);
    currentCategory.value = category;
    const questions = questionsByCategory.value[category] || [];
    const firstUnansweredIndex = questions.findIndex(q => answers.value[q.id] === undefined);
    currentQuestionIndex.value = firstUnansweredIndex !== -1 ? firstUnansweredIndex : questions.length - 1;
    window.scrollTo(0, 0);
  };
  
  const getDotClass = (questionId) => {
    if (answers.value[questionId] === undefined) {
    return 'unanswered';
    }
    return answers.value[questionId] ? 'answered-yes' : 'answered-no';
  };
  
  // Add these computed properties
  const currentQuestion = computed(() => {
    return questionsByCategory.value[currentCategory.value]?.[currentQuestionIndex.value];
  });
  
  const progress = computed(() => {
    const categoryQuestions = questionsByCategory.value[currentCategory.value] || [];
    return categoryQuestions.length ? 
    ((currentQuestionIndex.value + 1) / categoryQuestions.length) * 100 : 0;
  });
  
  const isAssessmentComplete = computed(() => {
    return Object.keys(answers.value).length === questions.value.length;
  });
  
  const navigateQuestion = (direction) => {
    const allQuestions = categories.value.flatMap(category => questionsByCategory.value[category]);
    const currentOverallIndex = allQuestions.findIndex(q => 
    q.category === currentCategory.value && 
    q.id === questionsByCategory.value[currentCategory.value][currentQuestionIndex.value].id
    );
  
    let newIndex = (currentOverallIndex + direction + allQuestions.length) % allQuestions.length;
  
    // Find the new category and question index
    const newQuestion = allQuestions[newIndex];
    currentCategory.value = newQuestion.category;
    currentQuestionIndex.value = questionsByCategory.value[currentCategory.value].findIndex(q => q.id === newQuestion.id);
  
    // Save the current position
    saveAssessment();
  };
  
  const answerQuestion = async (questionId, answer) => {
    answers.value[questionId] = answer;
    
    try {
    const currentCategoryQuestions = questionsByCategory.value[currentCategory.value];
    const nextQuestionIndex = currentQuestionIndex.value + 1;
  
    if (nextQuestionIndex < currentCategoryQuestions.length) {
      // Move to the next question in the current category
      currentQuestionIndex.value = nextQuestionIndex;
    } else {
      // We've reached the end of the current category
      const nextCategoryIndex = categories.value.indexOf(currentCategory.value) + 1;
      
      if (nextCategoryIndex < categories.value.length) {
      // Move to the next category
      currentCategory.value = categories.value[nextCategoryIndex];
      currentQuestionIndex.value = 0;
      } else {
      // We've reached the end of all categories
      if (isAllQuestionsAnswered()) {
        // All questions are answered, go to results
        await saveAssessment();
        router.push(`/results/${assessmentId.value}`);
        return;
      } else {
        // Find the first unanswered question from the beginning
        const firstUnansweredQuestion = findNextUnansweredQuestion();
        if (firstUnansweredQuestion) {
        currentCategory.value = firstUnansweredQuestion.category;
        currentQuestionIndex.value = questionsByCategory.value[currentCategory.value]
          .findIndex(q => q.id === firstUnansweredQuestion.id);
        }
      }
      }
    }
    
    await saveAssessment();
    } catch (error) {
    console.error('Error saving answer:', error);
    }
  };

  const fetchAssessment = async () => {
    loading.value = true;
    error.value = null;
    
    try {
      const authResponse = await auth.checkAuth();
      if (!authResponse.isAuthenticated) {
        router.push('/login');
        return;
      }
  
      // Get query parameters
      const isAdminView = route.query.adminView === 'true';
      const assessmentType = route.query.type;
  
      console.log('Fetching assessment:', {
        id: assessmentId.value,
        isAdmin: authResponse.user.isAdmin,
        type: assessmentType,
        isAdminView
      });
  
      const response = await axios.get(`/api/assessment/${assessmentId.value}`, {
        withCredentials: true,
        baseURL: 'https://mystoresafe.com',
        params: {
          type: assessmentType,
          adminView: isAdminView
        }
      });
  
      console.log('Assessment response:', response.data);
      const assessment = response.data;
      
      assessmentTitle.value = assessment.title;
      answers.value = assessment.answers || {};
      notes.value = assessment.notes || {};
      currentQuestionIndex.value = assessment.currentQuestionIndex || 0;
      currentCategory.value = assessment.currentCategory || categories.value[0];
      lastAnsweredQuestionIndex.value = assessment.lastAnsweredQuestionIndex || {};
  
      await fetchQuestions();
      initializeLastAnsweredIndices();
      
    } catch (err) {
      console.error('Error fetching assessment:', err);
      error.value = err.response?.data?.error || 'Failed to load assessment';
      if (err.response?.status === 401) {
        router.push('/login');
      }
    } finally {
      loading.value = false;
    }
  };

  const fetchQuestions = async () => {
    try {
      console.log('Fetching questions for user type:', store.state.auth?.user?.isRep ? 'Rep' : 'Customer');
      
      const response = await axios.get('/api/questions', {
        withCredentials: true,
        baseURL: process.env.VUE_APP_API_URL || 'https://mystoresafe.com',
        params: {
          type: store.state.auth?.user?.isRep ? 'rep' : 'customer'
        }
      });
  
      if (!response.data || !Array.isArray(response.data)) {
        throw new Error('Invalid questions data received');
      }
      
      console.log('Received questions:', {
        count: response.data.length,
        userType: store.state.auth?.user?.isRep ? 'Rep' : 'Customer',
        firstQuestion: response.data[0]
      });
  
      questions.value = response.data;
      organizeQuestionsByCategory();
      setInitialCategory();
    } catch (error) {
      console.error('Error fetching questions:', error);
      error.value = error.message || 'Failed to load questions';
    }
  };
  
  const getQuestionNumber = (currentCategory, questionIndex) => {
    let number = 1;
    for (const category of categories.value) {
      if (category === currentCategory) {
        number += questionIndex;
        break;
      }
      number += questionsByCategory.value[category]?.length || 0;
    }
    return number;
  };

  const saveAssessment = async () => {
    try {
      const payload = {
        title: assessmentTitle.value,
        answers: answers.value,
        notes: notes.value,  // This is all you need for notes
        currentCategory: currentCategory.value,
        currentQuestionIndex: currentQuestionIndex.value,
        completed: isAllQuestionsAnswered(),
        type: route.query.type
      };
  
      const response = await axios.put(`/api/assessment/${assessmentId.value}`, payload, {
        withCredentials: true,
        baseURL: 'https://mystoresafe.com',
        params: {
          type: route.query.type,
          adminView: route.query.adminView
        }
      });
  
      console.log('Assessment saved successfully:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error saving assessment:', error);
      throw error;
    }
  };
  
  watch([currentQuestion, notes], async (newVals, oldVals) => {
    if (oldVals[0]?.id && oldVals[1] && Object.keys(oldVals[1]).length > 0) {
      await saveAssessment();
    }
  });
  
  const addNote = (questionId, note) => {
    if (!assessment.notes[questionId]) {
      assessment.notes[questionId] = [];
    }
    assessment.notes[questionId].push(note);
  };
  
  const addImage = (questionId, image) => {
    if (!assessment.images[questionId]) {
      assessment.images[questionId] = [];
    }
    assessment.images[questionId].push(image);
  };
  
  const saveAndReturn = async () => {
    try {
    console.log('Starting save and return process');
    await saveAssessment();
    console.log('Assessment saved, navigating to dashboard');
    // Try both ways to ensure navigation works
    await router.push('/dashboard').catch(err => {
      console.error('Navigation failed, trying alternative:', err);
      window.location.href = '/dashboard';
    });
    } catch (error) {
    console.error('Error in save and return:', error);
    // Still try to navigate even if save fails
    router.push('/dashboard').catch(err => {
      window.location.href = '/dashboard';
    });
    }
  };
  
  const isAllQuestionsAnswered = () => {
    return questions.value.every(question => answers.value[question.id] !== undefined);
  };
  
  const findNextUnansweredQuestion = () => {
    for (const category of categories.value) {
    const unansweredQuestion = questionsByCategory.value[category]
      .find(q => answers.value[q.id] === undefined);
    if (unansweredQuestion) {
      return { ...unansweredQuestion, category };
    }
    }
    return null;
  };
  
  watch(
    () => currentQuestion.value?.id,
    async (newId) => {
      if (newId) {
        questionImages.value = [];
        await fetchQuestionImages(newId);
      }
    }, 
    { immediate: true }
  );

  onMounted(async () => {
    try {
    const authResponse = await auth.checkAuth();
    if (!authResponse.isAuthenticated) {
      console.log('Not authenticated, redirecting to login');
      router.push('/login');
      return;
    }
    console.log('Auth successful, fetching assessment');
    await fetchAssessment();
    } catch (error) {
    console.error('Error in mounted:', error);
    router.push('/login');
    }
  });

  return {
  // State
  assessmentTitle,
  questions,
  categories,
  currentCategory,
  currentQuestionIndex,
  questionsByCategory,
  answers,
  notes,
  loading,
  error,
  currentQuestion,
  isRep,
  questionImages,
  
  // Computed
  progress,
  isAssessmentComplete,
  
  // Methods
  fetchAssessment,
  fetchQuestions,
  organizeQuestionsByCategory,
  saveAndReturn,
  jumpToCategory,
  getDotClass,
  navigateQuestion,
  answerQuestion,
  saveAssessment,
  getQuestionNumber,
  isDragging,
  currentNotes,
  handleDrag,
  handleDrop,
  handleFileSelect,
  addNote,
  saveNotes,
  uploadImage,
  addImage,
  deleteImage
  };
  }
};
</script>